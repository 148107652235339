<template>
    <div class="con-wrap">
        <CarrotTitle title="MCR/MCG 관리">
            - 리드(센터장) 직급 이상의 리더에게 MCR/MCG 작성을 독려하기 위한 페이지입니다.<br>
            - MCR 필수 작성 수는 [팀원 수(본인 산하 직원 수)X 4회(연 MCR 최소 작성 수)]를 기준으로 노출됩니다.<br>
            - MCG 필수 작성 수는 [팀원 수(본인 산하 직원 수)X 12회(연 MCG 최소 작성 수)] 입니다.<br>
            - 체크박스 선택 후 노출을 클릭하면 7일간 당근농장 로그인 시 피드백 작성 독려 팝업이 노출됩니다.<br>
            &nbsp;&nbsp;(7일 뒤 자동으로 비노출로 변경됩니다.)
        </CarrotTitle>
        <div class="board">
            <div v-if="bbs.isAuth==true" class="mt-40">
                <div>

                    <div class="mb-20">
                        <select class="w-100px float-left mr-10" v-model="bbs.syear" @change="bbs.doInit">
                            <option :value="v" v-for="(v, k) in bbs.search_years" :key="k">{{ v }}</option>
                        </select>
                        <CarrotDept v-model="bbs.office" @change="bbs.doInit" class="w-200px mr-10"></CarrotDept>
                        <CarrotTeam v-model="bbs.team" :idx_office="bbs.office" @change="bbs.doInit" class="w-200px"></CarrotTeam>
                        <select v-model="bbs.is_display" @change="bbs.doInit" class="w-200px dp-inblock ml-10">
                            <option value="">상태 전체</option>
                            <option value="Y">노출</option>
                            <option value="N">비노출</option>
                        </select>
                        <button class="btn-default float-right h-30" @click="bbs.unsetDisplay">비노출하기</button>
                        <button class="btn-default float-right h-30" @click="bbs.setDisplay">노출하기</button>

                        <div class="clear"></div>
                    </div>
                    
                    <table class="table-col">
                        <colgroup>
                            <col width="50">
                            <col width="*">
                            <col width="150">
                            <col width="100">
                            <col width="160">
                            <col width="70">
                            <col width="110">
                            <col width="130">
                            <col width="110">
                            <col width="130">
                            <col width="70">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <label><input type="checkbox" v-model="bbs.isAll" @change="bbs.checkAll"></label>
                                </th>
                                <th>부문</th>
                                <th>본부</th>
                                <th>팀</th>
                                <th>이름</th>
                                <th>팀원 수</th>
                                <th>MCR 작성 수</th>
                                <th>MCR 작성률 
                                    <button v-if="bbs.ordmcr=='asc'" @click="bbs.changeOrder('mcrdesc')" class="btn-sort">▲</button>
                                    <button v-else @click="bbs.changeOrder('mcrasc')" class="btn-sort">▼</button>
                                </th>
                                <th>MCG 작성 수</th>
                                <th>MCG 작성률 
                                    <button v-if="bbs.ordmcg=='asc'" @click="bbs.changeOrder('mcgdesc')" class="btn-sort">▲</button>
                                    <button v-else @click="bbs.changeOrder('mcgasc')" class="btn-sort">▼</button>
                                </th>
                                <th>상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>
                                    <label><input type="checkbox" v-model="bbs.selected" :value="irow.idx_hq"></label>
                                </td>
                                <td>{{ irow.sector_name }}</td>
                                <td>{{ irow.office_name }}</td>
                                <td>{{ irow.team_name }}</td>
                                <td>{{ irow.ename }} ({{ irow.kname }})</td>
                                <td>{{ irow.cnt_member }}</td>
                                <td>{{ irow.cnt_mcr }}/{{ irow.tot_mcr }}</td>
                                <td>{{ irow.rate_mcr }}%</td>
                                <td>{{ irow.cnt_mcg }}/{{ irow.tot_mcg }}</td>
                                <td>{{ irow.rate_mcg }}%</td>
                                <td>{{ irow.state_txt }}</td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="10">항목이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import { useStore } from 'vuex';


export default {
    layout:"myPIMS",

    components: {
    },
    setup() {
        const router = useRouter();
        const toast  = useToast();
        const store  = useStore();

        const bbs = reactive({
            isMounted : false,
            isAuth    : false,
            search_years : [],
            selected : [],
            isAll : false,

            page : 1,
            rows : 50,

            syear  : "",
            office : "0",
            team   : "0",

            is_display : "",
            ordmcr : "asc",
            ordmcg : "asc",

            list : [], total : 0,

            checkAll : () => {
                bbs.selected = [];
                if( bbs.isAll == true ){
                    for(let i=0; i<bbs.list.length; i++){
                        let irow = bbs.list[i];
                        bbs.selected.push(irow.idx_hq);
                    }
                }
            },

            setDisplay : () => {
                if( bbs.selected.length <= 0 ){
                    Swal.fire({
                        title : 'Feedback 관리',
                        text  : '노출할 대상을 선택해 주세요.'
                    });
                    return;
                }

                let params = {
                    selected : bbs.selected,
                    state : "Y"
                };
                axios.post("/rest/mypims/setDisplay", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire({
                            title : 'Feedback 관리',
                            text  : '노출하였습니다'
                        }).then(() => {
                            bbs.selected = [];
                            bbs.doSearch();
                        });
                    } else {
                        console.info(res.data.err_msg);
                    }
                });
            },
            unsetDisplay : () => {
            if( bbs.selected.length <= 0 ){
              Swal.fire({
                title : 'Feedback 관리',
                text  : '비노출할 대상을 선택해 주세요.'
              });
              return;
            }

            let params = {
              selected : bbs.selected,
              state : "N"
            };
            axios.post("/rest/mypims/setDisplay", params).then((res) => {
              if( res.data.err == 0 ){
                Swal.fire({
                  title : 'Feedback 관리',
                  text  : '비노출하였습니다'
                }).then(() => {
                  bbs.selected = [];
                  bbs.doSearch();
                });
              } else {
                console.info(res.data.err_msg);
              }
            });
          },
            changeOrder : (ord) => {
                switch(ord){
                    case 'mcrasc':  bbs.ordmcr = 'asc';  break;
                    case 'mcrdesc': bbs.ordmcr = 'desc'; break;
                    case 'mcgasc':  bbs.ordmcg = 'asc';  break;
                    case 'mcgdesc': bbs.ordmcg = 'desc'; break;
                }
                bbs.doInit();
            },

            doInit : () => {
                bbs.page = 1;
                bbs.doSearch();
            },

            doSearch : () => {
                if( bbs.isMounted == false ) return;
                let params = {
                    page : bbs.page,
                    rows : bbs.rows,

                    syear  : bbs.syear,
                    office : bbs.office,
                    team   : bbs.team,
                    is_display : bbs.is_display,

                    ordmcr : bbs.ordmcr,
                    ordmcg : bbs.ordmcg,

                };
                axios.get('/rest/mypims/getFeedbackList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;

                        bbs.isAuth = res.data.is_auth=='Y'?true:false;

                        if( bbs.isAuth == false ){
                            Swal.fire({
                                title : 'MCR/MCG 관리',
                                text  : '권한이 없습니다.'
                            }).then(() => {
                                router.go(-1);
                            });
                        }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            makeSearchYears: () => {
                let cd = new Date();
                let cy = cd.getFullYear();
                for(let i=cy; i>=2019; i--){
                    bbs.search_years.push(i);
                }
            }
        });

        onMounted(() => {
            // Mounted
            // 현재는 팀 구성이 되어있지 않으므로 스탭을 지정하여 체크하도록 변경, 3995:슈리, 19054:헤이즐
            if( store.state.pd_auth == false  ) {
                router.go(-1);
                Swal.fire({
                    title : 'MCR/MCG 관리',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            let td = new Date();
            bbs.syear = td.getFullYear();

            bbs.makeSearchYears();
            bbs.isMounted = true;
            bbs.doInit();
        });

        return {bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>